import axios from "axios";


// const baseURL = axios.create({ baseURL: "http://shelly-ksa.com" });
// const baseURL = axios.create({ baseURL: "http://demo.megastore-app.com:3000" });
// const baseURL = axios.create({ baseURL: "http://api.abayatlyan.com:3000" });
// const baseURL = axios.create({ baseURL: "http://localhost" });
// const baseURL = axios.create({ baseURL: "https://abu6.com" });

const baseURL = axios.create({ baseURL: "http://167.172.53.107" });


export default baseURL;
